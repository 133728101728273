<template>
  <div class="classHour" v-loading="loading">
    <!--  标签页-->
    <el-tabs v-model="activeName"  class="el-nav" @tab-click="handleClick">
      <div class="el-nav-header flex-nowrap">
        <img :src="courseInfo.pc_cover_picture" class="table-img" alt="">
        <div class="header-r">
          <div class="table-item-name">{{ courseInfo.title }}</div>
          <div class="table-item-times flex-nowrap">
            <div class="table-item-time" v-if="!courseInfo.is_share">学习人数：{{ courseInfo.buy_num + courseInfo.learn_num > 9999 ? ((courseInfo.buy_num+ courseInfo.learn_num) / 10000 ).toFixed(2) + 'W': (courseInfo.buy_num+ courseInfo.learn_num) }}人学习<span style="color: #D0D6DE;margin: 0 8px">|</span></div>
            <div class="table-item-time">课程时长：{{ courseInfo.length ? $carbon.formatSeconds(courseInfo.length) : 0 }}</div>
          </div>
          <div>
            <el-tag
              style="margin-right: 20px;background:rgba(255, 165, 9, 0.1);border: unset;color: #FFA509;border-radius: 10px;padding: 0 10px;"
              v-for="(item,index) in courseInfo.tags"
              :key="index"
              size="mini"
              type="success"
              effect="dark">
              {{ item.name }}
            </el-tag>
          </div>
        </div>
      </div>
      <el-tab-pane label="课时管理" class="first" name="chapters"></el-tab-pane>
      <el-tab-pane label="课程媒体" class="two" name="media"></el-tab-pane>
      <el-tab-pane label="资料管理" class="three" name="materials"></el-tab-pane>
      <el-tab-pane :label="courseInfo.comment_count ? (courseInfo.comment_count > 99 ?  '留言 '+'99+' : '留言 '+courseInfo.comment_count) : '留言 '+ 0 " class="four" name="interact"  v-if="!courseInfo.is_share">
        <!--        搜索查询-->
        <div class="search space-between" style="margin: 0">
          <div class="flex-nowrap">
            <el-form ref="ruleForm" :inline="true"   class="demo-ruleForm">
              <el-form-item label="状态" prop="name">
                <el-select v-model="status" placeholder="请选择状态" class="search-type" >
                  <el-option
                      v-for="item in statusData"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="评论排序" prop="name">
              <el-select v-model="sort" placeholder="请选择评论排序" class="search-type" >
                <el-option
                    v-for="item in sortData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            </el-form>
            <el-button type="primary" class="search-btn" @click="searchSubmit">查询</el-button>
            <el-button class="search-btn reset" @click="resetFun">重置</el-button>
          </div>
        </div>

        <div class="total space-between" style="margin-top: 6px">
          <div class="total-left" style="margin-left: 0">
            <span class="total-left-t">全部评论</span>
            （<span class="total-left-text">{{interactData.meta ? interactData.meta.total : 0 }}</span>条）</div>
        </div>

        <div v-if="interactData.data && interactData.data.length">
          <comment :comment="interactData.data" :type="'course'" :uuid="uuid" :isreply="isReply" @commentsHandle="commentsChildren"></comment>
        </div>
        <empty-prompt v-else></empty-prompt>

        <el-pagination
            v-if="interactData.meta && interactData.meta.last_page > 1"
            class="page"
            background
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout=" prev, pager, next,total, jumper"
            :total="interactData.meta ? interactData.meta.total : 0">
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="学员管理" class="five" name="student"  v-if="!courseInfo.is_share"></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import _ from 'lodash'
import Comment from "components/comment";
import EmptyPrompt from "components/EmptyPrompt";
export default {
  name: "Interact",
  data() {
    return {
      activeName: 'interact',
      uuid: '',
      courseInfo: {}, // 获取课程信息
      interactData: {}, // 互动信息
      // 筛选信息
      sortData: [
        {
          label: '时间倒序',
          value: 1
        },
        {
          label: '时间升序',
          value: 2
        }
      ],  // 状态数据
      sort: '', // 排序
      status: '', //状态
      statusData: [
        {
          label: '待审核',
          value: 0
        },
        {
          label: '公开',
          value: 1
        }
      ], //状态数据
      pageSize: 20, // 分页条数
      page: 1,  // 分页
      isReply:false,
      loading:true,
    }
  },
  created() {
    if (this.$route.params.uuid) {
      this.uuid = this.$route.params.uuid
      this.queryFun()
      this.getCourses()
      this.getComments()
    }
  },
  methods: {
    // 获取课程信息
    getCourses() {
      let url = this.$api.Courses + '/' + this.uuid;
      this.$http.get(url, true).then(res => {
        if (res.data.success) {
          this.courseInfo = res.data.data;
          this.loading = false
          if (this.courseInfo.content_status === 3){
            this.isReply = false
          }else {
            this.isReply = true
          }
        }
      }).catch(err =>{
        this.loading = false
      })
    },
    // 获取互动信息
    getComments() {
      let url= this.$api.Comments+ `?type=course&uuid=${this.uuid}&sort=${this.sort}&status=${this.status}&pageSize=${this.pageSize}&page=${this.page}`
      this.$http.get(url, true).then(res =>{
        if(res.data.success) {
          this.interactData= res.data.data
        }else {
          this.$message.error(res.data.message)
        }
      }).catch(error =>{
        this.$message.error(error.response.data.message)
      })
    },
    // 重置
    resetFun() {
      this.sort= ''
      this.status= ''
      this.searchSubmit()
    },
    // 查询
    searchSubmit() {
      this.page= 1;
      this.linkFun();
      this.getComments()
    },
    // 获取当前选择
    handleCommand(command) {
      this.current_info= command[1]
      if(command[0] === 'del') {
        this.del_hierarchy= 'first'
        this.delDialogReply= true
      }else if(command[0] === 'reply') {
        this.isDialogReply= true
      }
    },
    // 讲师回复删除
    delInteract(data) {
      this.del_hierarchy= 'second'
      this.current_info= data
      this.delDialogReply= true
    },

    // 当前页码
    handleCurrentChange(val) {
      this.page= val
      this.linkFun()
      this.getComments()
    },
    // 评价更新
    commentsChildren(data){
      if(data === 'upload') {
        this.getComments()
      }
    },
    // 改变路由参数
    linkFun() {
      this.$router.push({path: `/courses/class/${this.uuid}/interact`,query: {
          options: this.options,
          keywords: this.keywords,
          level: this.level,
          status: this.status,
          page: this.page,
          pageSize: this.pageSize
        }})
    },
    // 参数赋值
    queryFun() {
      let query= this.$route.query;
      if(query) {
        if(query.options) {
          this.options= query.options;
        }
        if(query.keywords) {
          this.keywords= query.keywords;
        }
        if(query.level) {
          this.level= query.level;
        }
        if(query.status) {
          this.status= query.status;
        }
        if(query.page) {
          this.page= parseInt(query.page);
        }
        if(query.pageSize) {
          this.pageSize= parseInt(query.pageSize);
        }
      }
    },
    // tab切换想像
    handleClick(tab, event) {
      if(tab.name === 'chapters') {
        this.$router.push({path: `/courses/class/${this.uuid}/chapters`})
      }else if(tab.name === 'media') {
        this.$router.push({path: `/courses/class/${this.uuid}/media`})
      }else if(tab.name === 'materials') {
        this.$router.push({path: `/courses/class/${this.uuid}/materials`})
      }else if(tab.name === 'interact') {
        this.$router.push({path: `/courses/class/${this.uuid}/interact`})
      }else if(tab.name === 'student') {
        this.$router.push({path: `/courses/class/${this.uuid}/student`})
      }else {
        this.$router.push({path: `/courses/courses`})
      }
    }
  },
  components: {
    Comment,
    EmptyPrompt
  }
}
</script>

<style lang="less" scoped>
@import "~assets/less/courses/classHour/index.less";
.el-tab-pane{
  min-height: 528px;
}
</style>
